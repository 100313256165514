import logo from './rabbit.png'
import './App.css'

function App () {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Rabbit VPN  - Safe&Fast VPN
        </p>
        <a
          className="App-link"
          href="https://play.google.com/store/apps/details?id=com.rabbitvpn"
          target="_blank"
          rel="noopener noreferrer"
        >
          Little Rabbit VPN, user-friendly VPN with no limits.
        </a>
      </header>
    </div>
  )
}

export default App
